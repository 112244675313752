const Constant = {
    ACTION: {
        STORE: 'store/',
        DEFAULT: 'DEFAULT/',
        CATEGORY: 'category/',
        CONTENT: 'content/',
        PAGES: 'pages/',
        PRODUCT_GROUP: 'products/group/',
        PRODUCT: 'product/',
        PRODUCTS: 'products/',
        CART: 'cart/',
        CARTS: 'cart',
        PRICE: 'price/',
        CUSTOMER: 'customer/',
        LOGIN: 'login/',
        CUSTOMERS: 'customers/',
        MANUFACTURERS: 'manufacturers/',
        VARIANTS: 'variants/',
        CONFIG: 'config/',
        COUNTRY: 'country/',
        ZONES: 'zones/',
        AUTH: 'auth/',
        PROFILE: 'profile',
        SHIPPING: 'shipping',
        SHIPPING_COUNTRY: 'shipping/country',
        TOTAL: 'total/',
        NEWSLETTER: 'newsletter/',
        REGISTER: 'register',
        CHECKOUT: 'checkout',
        PASSWORD: 'password/',
        CONTACT: 'contact/',
        ORDERS: 'orders/',
        ADDRESS: 'address',
        RESET: 'reset/',
        REQUEST: 'request/',
        SEARCH: 'search/',
        AUTOCOMPLETE: 'autocomplete/',
        BOXES: 'boxes/',
        BANNER_TEXT: 'bannerText/',
        IMAGES: 'images/',
        HEADER_MESSAGE: 'headerMessage/',
        AGREEMENT: 'agreement/',
        PROMO: 'promo/'
    }
}


export default Constant